import React, { Component } from 'react';
import { Router, Switch } from 'dva/router';
import { Modal as ModalAntd } from 'antd';
import { connect } from 'dva';
import { RouteConfig } from './RouteConfig';
import classnames from 'classnames';
import { PageComponent } from 'jad-lib-react';
import { environment } from './enviroments';
import { isDotCom, isLogin, isShowLogin } from './utils/tool.service';
import { API } from './utils/api';
import { DomainName } from './components/DomainName/DomainName';
import { Header } from './components/Header';
const isCom = isDotCom();
@connect(({ global: { activePath, visible, userInfo, newWebsite } }) => {
    return {
        activePath,
        visible,
        userInfo,
        newWebsite,
    };
})
class App extends Component<
    PageComponent<{
        activePath?: any;
        visible?: any;
        userInfo?: any;
        newWebsite?: any;
    }>
> {
    constructor(props) {
        super(props);   
        let newWebsite =
            window.location.hostname.indexOf('chu-li.com') > -1 ||
            window.location.hostname.indexOf('localhost') > -1;
        if (!newWebsite) {
            import('./App.less');
            document.title = newWebsite
                ? '杭州矗立网络'
                : '极准-数据驱动的广告平台-极准广告-杭州极准-DSP广告投放-流量变现';
        } else {
            import('./App2.less');
            this.props.dispatch({
                type: 'global/updateType',
                payload: newWebsite,
            });
            document.title = newWebsite
                ? '杭州矗立网络'
                : '数据驱动的广告平台-DSP广告投放-流量变现';
        }
        if (window.location.hostname == 'www.szcaimi.cn') {
            console.log('跳到财米');
            document.title = '财米-数据驱动的广告平台';
            window.location.href = '/#/caimi';
        }
    }
    state = {
        ordervisible: false,
        successVisible: false,
        over: false,
        isMobile: true,
    };
    componentWillMount() {
        if (window.location.hostname == 'advertising.chinacloudflare.com') {
            if (window.location.hash.indexOf('landing/ydhk') == -1) {
                window.location.href = 'http://chinacloudflare.com/ ';
            }
        }
        this.onWatchMobile();
    }
    componentDidMount() {
        window['toggleVisible'] = this.toggleVisible.bind(this);
        window.addEventListener('resize', () => {
            this.onWatchMobile();
        });
        isLogin() && this.props.dispatch({ type: 'global/fetchUserInfo', payload: {} });
    }
    onWatchMobile() {
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    }
    toggleVisible(turn = true, isf) {
        let { ordervisible } = this.state;
        this.setState(state => {
            let successVisible = false;
            if (!turn && !isf) {
                successVisible = true;
            }
            return {
                ordervisible: turn,
                successVisible,
            };
        });
    }
    logout() {
        ModalAntd.confirm({
            title: '退出登录',
            content: '确定要退出登录吗',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                API.logout({}).then((res: any) => {
                    if (res.code == 2000) {
                        setTimeout(() => {
                            this.props.history.push('/');
                            this.props.dispatch({
                                type: 'global/updateUserInfo',
                                payload: {},
                            });
                        }, 50);
                    }
                });
            },
        });
    }
    render() {
        const { history } = this.props;
        let { isMobile } = this.state;
        let { activePath, visible, newWebsite } = this.props;
        console.log(activePath, 'activePath');

        return (
            <React.Fragment>
                {!newWebsite && <Header />}
                <Router history={history}>
                    <Switch>
                        <RouteConfig history={history} />
                    </Switch>
                </Router>

                {!newWebsite && (
                    <DomainName newWebsite={newWebsite}></DomainName>
                )}
            </React.Fragment>
        );
    }
}

export default App;
